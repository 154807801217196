import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// React icons
// import { FaPlay } from 'react-icons/fa';

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

import sponsorshipStyle from "assets/jss/material-kit-react/views/sponsorship.jsx"

// Sections for this page
// import ProductSection from "../Sponsorship/Sections/ProductSection.jsx";
import TeamSection from "../Sponsorship/Sections/TeamSection.jsx"
import WorkSection from "../Sponsorship/Sections/WorkSection.jsx"
import SEO from "../../components/SEO.jsx";

const dashboardRoutes = []

class Sponsorship extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <SEO/>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/beach2020.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>You can be part of our story.</h1>
                <br />
                {/* Make video to embed here */}
                {/* <Button
                  color="danger"
                  size="lg"
                  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPlay/>
                  Watch video
                </Button> */}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <br/>
                <br/>
                <h4 style={{ color: "#123E78" }}>
                  West Coast Beach Volleyball Club is a new and growing sports
                  club. Our goals are, first and foremost, to grow the sport of
                  beach volleyball in the West of Scotland and be the biggest
                  beach club in the country. We are lucky to
                  be able to rely on the hard work of volunteers and members to
                  operate. However, we are always looking for partners to help
                  us grow as a club and improve the experience of our members.
                  We are a group of committed, passionate players and would love
                  to work together with local businesses to help grow a sport we
                  love whilst increasing your exposure. So if you would like to
                  be associated with a club promoting being active, getting
                  outdoors and the benefits of sport in the community we would
                  love to hear from you.
                </h4>
              </GridItem>
            </GridContainer>
            <TeamSection />
            <WorkSection />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(sponsorshipStyle)(Sponsorship)
